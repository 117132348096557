#appContainer {
    width: calc(100% - 280px);
    height: calc(100vh);
    margin-left: 280px;
    overflow-y: scroll;
    padding: 16px;
    position: relative;
}

#appContainer.extended{
    width: 100%;
    margin-left: 0;
}

@media screen and (max-width:768px) {
    #appContainer{
        width: 100%;
        height: calc(100vh - 140px);
        margin-left: 0;
    }
    #appContainer.extended {
        height: calc(100vh - 90px);
    }
}

.page_title{
    display: flex;
    align-items: center;
    font-weight: 700;
}
.page_title img{
    margin-right: 8px;
}
.section_header{
    position: fixed;
    top: 0;
    right: 0;
    width: calc(100% - 280px);
    margin-left: 280px;
    background: #fff;
    padding: 12px 16px;
    box-shadow: 0px 0px 12px 0px #7D7D7D33;
    z-index: 100;
}
.section_header.extended{
    width: 100%;
    margin-left: 0;
}
@media screen and (max-width:768px) {
    .section_header {
        width: 100%;
        box-shadow: none;
        margin-left: 0;
    }
}

/*ANIMATION*/
.fadeIn {
    animation: 0.5s fadeIn forwards;
}

.fadeOut {
    animation: 0.5s fadeOut forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
.exam_pagination_control {
    width: 98%;
    position: fixed;
    left: 0;
    bottom: 0;
}



.exam_pagination_control button:disabled {
    border-color: #F0F0F0;
    color: #F0F0F0;
    opacity: 1;
    cursor:not-allowed
}

.calculator_container{
    position: fixed;
    top: 100px;
    left:100px;
    cursor:move
}
.exam_pagination_control__content{
    background: #fff;
    padding: 0 18px 12px;
}
.arrow_pagination_control img{
    width: 100%;   
    
}
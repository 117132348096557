.folders_list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 24px;
    align-items: start;
}
.folders_list.rounded {
    grid-template-columns: repeat(5, 1fr);
}
@media screen and (max-width:767px) {
    .folders_list {
        grid-template-columns: repeat(1, 1fr);
    }
    .folders_list.rounded {
        grid-template-columns: repeat(2, 1fr);
    }
    .folders_list .folder.rounded:nth-last-child(1):nth-child(odd) {
        grid-column-end: span 2;
    }
}


.folder {
    box-shadow: 0px 0px 12px 0px #7D7D7D33;
    border-radius: 16px;
    border: 2px solid #fff;
    background: #fff;
}
.folder.rounded {
    box-shadow: none;
    border-radius: 0;
    border: 0;
    text-align: center;
    background: transparent;
}
.folder.rounded .folder__title h3{
    font-weight: 500;
}
.folder.rounded .folder__image{
    width: 90px;
    height: 90px;
    border-radius: 100px;
    margin: auto;
    background: #F9FBFF;
    border: 2px solid #E0EAFD;
    display: flex;
    align-items: center;
    justify-content: center;
}


.folder .folder__content{
    padding: 16px;
}

.folder .folder__content .folder_icon{
    width: 20px;
}
.folder.rounded .folder__image .folder_icon {
    width: 65%;
}

.folder.rounded .folder__title {
    max-width: 98%;
    margin: 0 auto 4px auto;
}

.folder.rounded .folder__content  {
   padding: 0;
}

.folder.rounded .folder__details{
    margin-top: -12px;
}

.folder.rounded .folder__details img{
    width: 24px;
}

.folder_topbar {
    position: fixed;
    background: #fff;
    top: 0;
    left: 280px;
    width: calc(100% - 280px);
    min-height: 40px;
    padding: 12px 16px;
    box-shadow: 0px 0px 12px 0px #7D7D7D33;
    z-index: 100;
}
.folder_topbar.full-width {
    top: 0;
    left: 0;
    width: 100%;
}
.folder_topbar a{
    display: flex;
    align-items: center;
}
.folder_topbar a img{
    margin-right: 4px;
}
@media screen and (max-width:767px) {
    .folder_topbar {
        left: 0;
        width: 100%;
        box-shadow: none;
        padding: 20px 16px;
    }
}
.file_list, .video_list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
}
@media screen and (max-width:1024px) {
    .video_list {
        grid-template-columns: repeat(3, 1fr);
        align-items: end;
    }
}
@media screen and (max-width:767px) {
    .file_list {
        grid-template-columns: repeat(2, 1fr);
    }
    .video_list {
        grid-template-columns: repeat(1, 1fr);
        align-items: end;
    }
    .folder-content .file_list, .folder-content .video_list {
        width: 100%;
        overflow-x: scroll;
    }
}

.file{
    display: flex;
    flex-direction: column;
    word-break: break-word;
    text-align: center ;
    align-items: center;
}
.file img{
    max-width: 56px;
}
.file span, .video span{
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    text-overflow: ellipsis;    
    overflow: hidden;
    white-space: wrap;
    max-width: 220px;
}
.video span{
    max-width: none;
    white-space: wrap;
}

@media screen and (max-width:767px) {
    .file span {
        font-size: 14px;
        max-width: 250px;
    }
}

.folder-content{
    width: 100%;
}

.video {
    display: flex;
    flex-direction: column;
    align-items: start;
    word-break: break-word;
}
.video .video_preview{
    min-width: 210px;
    max-width: 210px;
    min-height: 120px;
    max-height: 120px;
    border-radius: 16px;
    overflow: hidden;
}
@media screen and (max-width: 768px) {
    .video .video_preview {
        width: 100%;
        max-width: none;
        max-height: none;
        border-radius: 16px;
    }
    
}
.video_player_inline {
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
    min-height: 180px;
}
.video_loader{
    width: 100%;
    min-height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.link_list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
}
.link_list .link{
    box-shadow: 0px 0px 12px 0px #7D7D7D33;
    border-radius: 16px;
    border: 2px solid #fff;
    background: #fff;
    padding: 12px;
}

@media screen and (max-width:767px) {
    .link_list {
        grid-template-columns: repeat(1, 1fr);
    }
}

/*Video Player*/
.video_player{
    max-height: 50vh;
}
#onboardingScreen {
    display: block;
    padding: 0;
    height: 100vh;
    overflow-y: scroll;
}
#onboardingScreen header{
    width: 100%;
    
}
#onboardingScreen header img{
    max-width: 250px;
    max-height: 64px;
    padding: 8px;
}
#onboardingScreen header .progress {
    border-radius: 0;
    height: 12px;
}
#onboardingScreen header .progress .progress-bar {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    height: 12px;
    background: #BABDCC;
}
.step{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 24px;
    max-width: 550px;
    margin: auto;
}
ul.next_steps{
    list-style: none;
    margin-top: 3rem;
    margin-left: 0;
    padding-left: 0;
    max-width: 450px;
}
ul.next_steps li{
    display: flex;
    margin-bottom: 3rem;
    align-items: center;
    position: relative;
}
ul.next_steps li::before {
    content: '';
    position: absolute;
    border-left: 2px dashed #BABDCC;
    left: 3.5%;
    top: 105%;
    z-index: 0;
    height: 100%;
}
ul.next_steps li.first::before {
    border-left: 2px solid #436094;
}

ul.next_steps li:last-child::before {
    content: none;
}
ul.next_steps li img{
    margin-right: 1rem;
}

@media screen and (max-width: 768px) {
    .step {
        align-items: flex-start;
    }
    ul.next_steps {
        list-style: none;
        margin-top: 3rem;
        margin-left: 0;
        max-width: 300px;
    }
    ul.next_steps li::before {
        left: 4.5%;
    }
}
label {
    color: #6D6F73;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

input, select, textarea, .react-date-picker, .react-select__menu-list, .react-select__menu{
    font-family: font_text, Nunito, Quicksand;
}

.form-control{
    border: 1px solid #BABDCC;
}

.form-control:focus{
    box-shadow: none;
    outline: none;
    border-color: var(--primary-color);
}

input[type="text"],
input[type="email"],
input[type="date"],
.react-date-picker
{
    padding: 12px 16px;
    border-radius: 12px;
    background: #F4F4F4;
    outline: none;
    font-size: 16px;
    position: relative;
}

.form-floating:not(:has(input:disabled))::after {
    content: url(../assets/images/edit_field.svg);
    position: absolute;
    bottom: 10px;
    right: 5px;
    width: 24px;
    height: 24px;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="date"]:focus,
.react-date-picker:focus
{
    background: #fff;
    border: 1px solid var(--primary-color);
}

input:disabled{
    color: #6D6F73;
    background: #FFFDFC;
}

.form-control:disabled{
    color: #6D6F73;
    background: #FFFDFC;
}

.form-check{
    min-height: auto;
    margin-bottom: 0;
    display: flex;
    align-items: center;
}
.form-check label{
    margin-left: 0.5rem;
}

.form-check-input{
    border: 2px solid var(--primary-color);
    outline: none;
    border-radius: 4px;
}
.form-check-input:focus {
    border-color: var(--primary-color);
    outline: 0;
    box-shadow: none;
}
.form-check-input:checked{
    background-color: var(--primary-color);
}

@media screen and (min-width: 768px) {
    input:not([type="checkbox"]):not([type="radio"]) {
        min-width: 450px
    }
    .react-date-picker__inputGroup__input{
        min-width: 0 !important;
    }
}

.form-floating>.form-control:not(:placeholder-shown)~label::after{
    background: transparent;
}


/*DATE PICKER*/

.react-date-picker__wrapper{
    border: none !important;
}
.react-date-picker__calendar{
    z-index: 1000 !important;
}

/*SELECT*/
.form-floating>label{
    z-index: 0;
}
.custom_react-select-option{
    padding: 12px;
    border: 1px solid #E2E2E2;
    border-radius: 12px;
    margin: 8px 0;
}
.custom_react-select-option.selected {
    border: 2px solid var(--primary-color);
    background: #ebebeb;
}
.react-select__menu,
.react-select__menu-list {
    border: 0 !important;
    border-radius: 0 !important;
    border-color: transparent !important;
    box-shadow: none;
    z-index: 10;
    background: #fff;
}
.react-select__menu{
    position: relative !important;
    margin-top: 0 !important;
    box-shadow: none !important;
}
.select-form-control{
    padding: 0rem 0.75rem;
    border-radius: 12px;
    background: #F4F4F4;
    outline: none;
    font-size: 16px;
    margin-top: 0 !important;
    border: 1px solid #BABDCC;
}
.react-select__value-container{
    padding: 0 !important;
}
.react-select__value-container{
    overflow: visible !important;
}
.select-form-control .react-select__control{
    background: transparent !important;
    border: none;
    padding: 1rem 0 0.1rem;
    box-shadow: none;
}
.select-form-control .react-select__control:focus {
    background: transparent !important;
    border: none;
    outline: none;
}
.select-form-control:focus-within {
    background: #fff;
    border: 1px solid var(--primary-color);
}

.ofa_banner_top{
    position: fixed;
    right: 0px;
    top: 32px;
    border-radius: 24px 0 0 24px;
    display: flex;
    align-items: center;
    background: linear-gradient(225.39deg, #436094 0%, #39517D 100%);
    width: fit-content;
    padding: 16px 16px;
    color: #fff;
    z-index: 10000;
    justify-content: center;
}
.ofa_banner_top h3 {
    font-size: 20px;
}
.ofa_banner_top p {
    font-size: 16px;
}
@media screen and (max-width: 768px) {
    .ofa_banner_top {
        top: 0;
        left: 0;
        width: 100%;
        padding: 16px 8px;
        justify-content: space-around;
        border-radius: 0;
    }
    .ofa_banner_top h3 {
        font-size: 16px;
    }
    .ofa_banner_top p {
        font-size: 12px;
    }
}

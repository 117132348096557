.desktop_reminder{
    position: fixed;
    top: 16px;
    right: 16px;
    width: 48px;
    height: 48px;
    z-index: 1000;
}
.desktop_reminder img{
    width: 100%;
}
.navigation_bar {
    padding-top: 35px;
    height: calc(100vh - 160px);
    overflow: hidden scroll;
}

.exam_timer {
    font-size: 40px;
    font-weight: 700;
}
.time_pbar {
    height: 12px;
}

.progress-bar {
    background: linear-gradient(270deg, #436094 1.2%, var(--primary-color) 100%);
    border-radius: var(--bs-progress-border-radius);
}
.progress-bar.bg-success {
    background: #65AA20;
}

.arrow_navigation{
    background: var(--primary-color);
    border-radius: 100px;
    width: 24px;
    height: 24px;
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
}
.arrow_navigation img{
    height: 80%;
}

@media screen and (max-width:768px){
    .exam_timer{ font-size: 16px; }
}

.answer_navigation_result {
    position: absolute;
    bottom: -10px;
    right: -10px;
    width: 20px;
}

.question_el {
    position: relative;
}
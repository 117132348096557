.exam_question_container.training_container{
  height: calc(100vh - 55px);
}

@media screen and (max-width:768px) {
  .exam_question_container.training_container {
    height: calc(100vh - 165px);
    padding-top: 8px;
  }
}

.exam_question_container p{
  font-size: 18px;
  font-weight: 700;
  color: #181818;
}

.answer {
    padding: 18px 24px;
    background: #FFFFFF;
    border-radius: 12px;
    margin-top: 8px;
    width: 100%;
    cursor: pointer;
    border: 2px solid #E2E2E2
}

.answer label{
  font-size: 18px;
  color: #454545;
}

.answer_feedback{
  opacity: 0;
  width: 0;
}

.answer.correct_answer .answer_feedback.correct_feedback {
  opacity: 1;
  width: 24px;
}
.answer.wrong_answer .answer_feedback.wrong_feedback {
  opacity: 1;
  width: 24px;
}

.block {
    margin-top: 24px;
    width: 100%;
}

.answer.selected {
    border: 2px solid var(--primary-color);
}

.form-check-input {
    margin-top: .10em;
}

.reset_answer {
    text-decoration-line: underline;
    color:var(--primary-color);
    cursor: pointer;
}
.textarea_text_question{
    min-height: 150px;
    width: 100%;
    margin-top: 16px;
    background: #FFFFFF;
    border: 2px solid var(--primary-color);
    box-sizing: border-box;
    border-radius: 8px;
}
.textarea_text_question:focus, .textarea_text_question:hover{
    border: 2px solid var(--primary-color);
}

.text_answer_limit{
    display: flex;
    justify-content: flex-end;
}
textarea:focus-visible{
    outline: none !important;
}
.over_limit_text_answer, .over_limit_text_answer:focus, .over_limit_text_answer:hover, .over_limit_text_answer:focus-within, .over_limit_text_answer:focus-visible{
    border: 2px solid #C63838 !important;
    outline: none;
}
.textarea_bottom_label{
    position: absolute;
    left: 0;
    padding: 5px 10px;
    bottom: -28px;
    background: #eee;
    color:var(--primary-color);
    border-radius: 0 0 20px 20px;
}
.textarea_box{
    position: relative;
    width: 100%;
}


.lds-ring {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--primary-color);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color:var(--primary-color) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.disable_saved{
  opacity: 0.7;
}

.hidden_radio{
  visibility: hidden;
  width: 0;
  float: none !important;
}

.reader_preview {
  background: #FFF8E8;
  border-radius: 14px;
  max-height: 150px;
  overflow: visible;
  border: 4px solid #ECECEC;
  padding: 8px;
  position: relative;
  cursor: pointer;
}

.reader_preview::after {
  content: '';
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  background-image: url('../../../assets/images/questions/reader_expand.svg');
  background-size: cover;
}

.reader_preview p {
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #373737;
  overflow: hidden;
  max-height: 130px;
  display: block;
}

.answer.correct_answer {
  border-color: #65AA20;
  transition: border-color 0.2s;
}

.answer.wrong_answer {
  border-color: #C63838;
  transition: border-color 0.52s;
}

.answer.missing_answer {
  border: 4px dashed #CDCDCD
}

.answer_label_number {
  color: #BABDCC;
  margin-right: 12px;
  font-family: font_text, Nunito, Quicksand;
}

.pill_text {
  font-size: 18px;
  font-weight: 500;
  line-height: 120%;
}

@media screen and (max-width:769px) {
  .pill_text {
    font-size: 16px;
    line-height: 120%;
  }
}
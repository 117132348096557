.exams_list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
}

@media screen and (max-width:767px) {
    .exams_list {
        grid-template-columns: repeat(1, 1fr);
    }
}

.exam_card {
    box-shadow: 0px 0px 12px 0px #7D7D7D33;
    border-radius: 16px;
    border: 2px solid #fff;
    background: #fff;
}

.exam_card_label {
    width: 100%;
    border-radius: 16px 16px 0 0;
    background: #C9492B;
    color: #FFF;
    text-align: center;
    font-size: 14px;
    padding: 4px 0;
}

.exam_card_label span {
    color: #fff;
}

.exam_card_content {
    padding: 16px;
}

#examPreviewScreen {
    padding-top: 4.5rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 80vh;
}

#examPerformScreen {
    margin-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

@media screen and (max-width:768px) {
    #examPerformScreen {
        margin-top: 0.5rem;
    }

    #examPreviewScreen {
        padding-top: 1.5rem;
        height: 100%;
    }

    #appContainer.extended:has(#examPerformScreen) {
        height: calc(100vh - 240px);
        margin-top: 34px;
    }
}

.exam_header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    padding: 12px 24px;
    box-shadow: 0px 0px 12px 0px #7D7D7D33;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
}

.saving_backdrop {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100000;
}

.exam_score {
    font-size: 16px;
    color: #454545;
    font-weight: 500;
    font-family: font_text, Nunito, Quicksand;
}

.exam_score strong {
    font-size: 20px;
}

.exam_labels {
    color: #454545;
    font-weight: 500;
    font-family: font_text,
        Quicksand;
    font-size: 16px;
}

/*SCOREBOARD*/
.scoreboard {
    max-width: 400px;
    margin: auto;
}

.scoreboard_card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
}

.scoreboard_card.active {
    background: #436094;
    border-radius: 16px;
    color: #fff !important;
    padding: 16px;
}

.scoreboard_card.active span {
    color: #fff !important;
}

.scoreboard_card .scoreboard_card__content {
    display: flex;
    align-items: center;
}

.scoreboard_card .scoreboard_card__image {
    width: 40px;
    height: 40px;
    position: relative;
}

.scoreboard_card .scoreboard_card__image img {
    width: 100%;
    height: 40px;
    border-radius: 100%;
}
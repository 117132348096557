.zoomable {
    cursor: pointer;
    transition: 0.2s;
    position: relative;
    border: 4px solid #E2E2E2;
    background: #FFFDFC;
    border-radius: 16px;
    max-width: 450px;
    overflow: hidden;
}

.zoomable img{
    width: 100%;
}

.zoom-in {
    position: fixed;
    background: #fff;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10000;
    cursor: zoom-out;
    max-width: none;
    border-radius: 0;
    border: 0;
}

.zoom-in img {
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);
    width: 80% !important;
    height: auto !important;
    max-height: none !important;
}

.zoom_icon {
    display: block;
    position: absolute;
    bottom: 15px;
    right: 15px;
    width: 40px;
    height: 40px;
    background: url("./lens.svg") center no-repeat;
    background-size: cover;
    z-index: 1000;
    cursor: zoom-in;
    transition: 0.2s;
}

.zoom-in .zoom_icon {
    top: 20px;
    right: 20px;
    background: url("./close.svg") center no-repeat;
    cursor: pointer;
}
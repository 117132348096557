.navigation_bar {
    padding-top: 35px;
    height: calc(100vh - 160px);
    overflow: hidden scroll;
}

.exam_timer {
    font-size: 40px;
    font-weight: 700;
}

.questions_nav {
    display: flex;
    flex-wrap: wrap;
}

@media screen and (max-width:768px) {
    .questions_nav  {
        flex-wrap: nowrap !important;
        overflow-x: scroll;
        overflow-y: hidden;
    }
    .questions_nav.sheet_navigation {
        flex-wrap: wrap !important;
        overflow-x: scroll;
    }
}

.questions_nav .question_el {
    background: #fff;
    border: 4px solid #ECECEC;
    border-radius: 12px;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #333333;
    width: 40px;
    padding: 6px 0;
    text-align: center;
    cursor: pointer;
    margin: 2px 0;
    font-family: font_text, Nunito, Quicksand;
}

.questions_nav .question_el_external {
    border: 3px dashed transparent;
    margin-top: 8px;
}

@media screen and (max-width:768px) {
    .questions_nav .question_el_external {
        margin-top: 0; 
    }
}

.questions_nav .question_el_external.block_wrapped {
    border-top: 3px dashed #a4b5c0;
    border-bottom: 3px dashed #a4b5c0;
}

.questions_nav .question_el_external.block_wrapped_first {
    border-left: 3px dashed #a4b5c0;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

.questions_nav .question_el_external.block_wrapped_last {
    border-right: 3px dashed #a4b5c0;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    margin-right: 8px;
}

.questions_nav .question_el.answered {
    background: #fff;
    border: 4px solid var(--primary-color);
    ;
    color: #333333;
}

.questions_nav .question_el.is_uncertain {
    border: 4px solid #FCBB0D;
}

.questions_nav .question_el.current {
    color: #fff;
    border: 4px solid var(--primary-color);
    background:var(--primary-color);
}

.questions_nav .question_el.disabled_question {
    opacity: 0.6;
}

.time_pbar {
    height: 12px;
}

.progress-bar {
    background: linear-gradient(270deg, #436094 1.2%, var(--primary-color) 100%);
    border-radius: var(--bs-progress-border-radius);
}

.arrow_navigation{
    background: var(--primary-color);
    border-radius: 100px;
    width: 24px;
    height: 24px;
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
}
.arrow_navigation img{
    height: 80%;
}

@media screen and (max-width:768px){
    .exam_timer{ font-size: 16px; }
}

.answer_navigation_result {
    position: absolute;
    bottom: -10px;
    right: -10px;
    width: 20px;
}

.question_el {
    position: relative;
}
#mainNavBar {
    position: fixed;
    top: 0;
    left: 0;
    width: auto;
    min-width: 280px;
    max-width: 280px;
    background: #FFFDFC;
    padding: 16px 32px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    z-index: 10;
    border-right: 3px solid #E2E2E2;
}

#mainNavBar ul {
    list-style: none;
    display: block;
    justify-content: space-around;
    padding-left: 0;
    margin-top: 32px;
}

#mainNavBar ul li {
    margin-bottom: 32px;
    cursor: pointer;
}

#mainNavBar ul li a {
    color: #6D6F73;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    text-decoration: none;
    font-weight: 500;
}

#mainNavBar ul li a span {
    color: #6D6F73;
}

#mainNavBar ul li a img {
    max-width: 28px;
    margin-right: 8px;
}

#mainNavBar ul li a.active_section {
    background: #FFFDFC;
    color: #181818;
    border-radius: 32px;
    font-weight: 700;
}

#mainNavBar ul li a.active_section span {
    color: #181818;
}

#navbarLogo {
    min-height: 140px;
    max-height: 200px;
    display: flex;
    align-items: center;
    max-width: 80%;
}

#navbarLogo img {
    width: 100%;
}


@media screen and (max-width:768px) {
    #mainNavBar {
        position: fixed;
        top: unset;
        bottom: 0;
        left: 0;
        width: 100%;
        border-radius: 24px 24px 0px 0px;
        box-shadow: 0px 0px 12px 0px rgba(125, 125, 125, 0.20);
        background: #FFFDFC;
        padding: 16px 24px 32px;
        height: auto;
        max-width: none;
        min-width: none;
    }

    #mainNavBar.webview_navbar {
        bottom: 3rem;
        width: fit-content;
        left: 50%;
        border-radius: 24px;
        box-shadow: 0px 0px 12px 0px #7B7B7B66;
        padding: 16px 24px;
        transform: translate(-50%, 0%);
    }

    #mainNavBar ul {
        list-style: none;
        display: flex;
        justify-content: space-around;
        padding-left: 0;
        margin-bottom: 0;
        margin-top: 0;
    }

    #mainNavBar ul li {
        margin-bottom: 0;
    }

    #mainNavBar ul li a img {
        filter: saturate(0);
    }

    #mainNavBar ul li a span {
        display: none;
    }

    #mainNavBar ul li a.active_section img {
        filter: none;
    }

    #navbarLogo {
        min-height: 0
    }
}
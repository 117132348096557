#userImage{
    margin: auto;
    max-width: 140px;
    position: relative;
}
#userImage img{
    width: 100%;
    height: 140px;
    border-radius: 100%;
}
#imageEdit {
    width: 50px !important;
    height: 50px !important;
    position: absolute;
    bottom: -10px;
    right: 0px;
    cursor: pointer;
}

#userAvatarModule .avatars {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
}

#userAvatarModule .avatars img {
    width: 100%;
}

#userAvatarModule .selected {
    border: 5px solid var(--primary-color);
    border-radius: 100%;
}
#userAccountMenu {
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}
#userAccountModule {
    max-height: 85vh;
    overflow-y: scroll;
}
#userAccountModule.active {
    margin-top: 4rem;
}

@media screen and (min-width:768px) {
    #userAccountMenu{
        position: fixed;
        display: block;
        bottom: 0;
        right: 40px;
        border: 3px solid #E2E2E2;
        padding: 24px 32px;
        border-radius: 32px 32px 0 0;
        border-bottom: 0;
        height: auto;
        background: #fff;
    }
    #userAccountModule {
        max-height: none;
        margin-top: 2rem;
        overflow-y: auto;
    }
    #userImage{
        margin-top: -50%;
    }
    #userAccountModule {
        padding: 12px 24px;
        max-width: 650px;
    }
    #userAvatarModule .avatars {
        grid-template-columns: repeat(5, 1fr);
    }
}

@media screen and (min-width:768px) and (max-width: 1180px){
    #userAccountModule {
        max-width: 500px;
    }
}

@media screen and (min-width:1520px) {
    #userAccountModule {
        max-width: 900px;
    }
}

.st_list li {
    padding:12px;
}
.st_list li.active{
    background: #F4F4F4;
    border-radius: 16px;
    font-weight: 700;
}

.contact_cards{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
}

@media screen and (max-width:768px) {
    .contact_cards {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 16px;
        padding: 8px;
    }
}

.contact_card{
    box-shadow: 0px 0px 12px 0px #7B7B7B66;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    cursor: pointer;
    align-items: start;
    width: 100%;
    background: #fff;
    justify-content: space-between;
}
@media screen and (min-width:769px) {
    .contact_cards {
        width: 100%;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300..700&display=swap');

/*Generic Style*/
.fh-div{
    height: 100%;
}

::-webkit-scrollbar {
    display: none;
}
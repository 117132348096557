.reader_page{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: #FFF8E8;
    z-index: 1000;
}
.backButtonReader{
    display: flex;
    align-items: center;
    cursor: pointer;
    vertical-align: middle;
}
.backButtonReader img{
    width: 12px;
}
.reader_header{
    margin-top: 40px;
}
.reader_header hr{
    background: #F8EACA;
    border-radius: 7px;
    width: 100%;
    height: 6px;
}
.reader_content{
    overflow-y: scroll;
    max-width: 730px;
    height: 75vh;
    margin: auto;
}

.tool{
    margin: 0 32px;
    max-width: 64px;
    cursor: pointer;
}

@media screen and (max-width:768px) {
    .reader_header{
        margin-top: 16px;
    }
    .tool{
        margin: 0 16px;
    }
}

.tool.active{
    border-radius: 16px;
}
.tool img{
    width: 100%;
    vertical-align: middle;
    min-height: 40px;
}
.reader_font_selector{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background:#fff;
    padding: 50px;
}
.slider_container{
    max-width: 230px;
    margin: auto;
    display: flex;
    align-items: center;
}
.paper_bookmark{
    background: url('../../../assets/images/reader/bookmark_text.svg') center;
    width: 16px;
    vertical-align: middle;
    display: inline-block;
    height: 22px;
    background-repeat: no-repeat;
    margin: 0 4px;
    background-size: contain;
    position: relative;
}
.reader_content.bookmark .paper_bookmark::before {
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    background: url(../../../assets/images/remove-bookmark.svg) center;
    background-size: cover;
    left: -15px;
    top: -15px;
}
.rc-slider-handle{
    background:var(--primary-color);
    border-color:var(--primary-color);
}
.rc-slider-dot{
    background:var(--primary-color);
    border: none;
}
.rc-slider-dot-active{
    background:var(--primary-color);
    border: none;
}
.rc-slider-track{
    display: none;
}
.rc-slider-step{
    height: 4px;
}
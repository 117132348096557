#trainingResultScreen{
    padding-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 90vh;
    position: relative;
}

@media screen and (max-width:768px) {
    #trainingResultScreen {
        padding-top: 0;
        height: 85vh;
    }
}

.training_header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    padding: 12px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.confetti_animation{
    position: absolute;
    left: 0%;
    top: 0;
    width: 100%;
    z-index: -1;
}
@media screen and (max-width:768px) {
    .confetti_animation {
        position: absolute;
        left: 0%;
        top: 0;
        width: 100%;
        transform: none;
    }
}

#trainingPerformScreen {
    margin-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

@media screen and (max-width:768px) {
    #trainingPerformScreen {
        margin-top: 0.5rem;
    }
}
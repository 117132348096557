html, body{
  margin: 0;
  padding: 0;
}
#App{
  width: 100%;
  height: 100vh;
}
@media screen and (max-width:768px){
  #App {
    height: calc(100vh - 5rem);
  }
}

:focus-visible {
  outline: none
}

span{
  color: #454545;
}
h1{
  font-family: font_h1, Nunito, Quicksand;
}
h2{
  font-family: font_h2, Nunito, Quicksand;
}
h3 {
  font-family: font_h3, Nunito, Quicksand;
}
a {
  font-family: font_link, Nunito, Quicksand;
  color: var(--link-color);
  font-weight: 700;
}
p, label, span, small{
  font-family: font_text, Nunito, Quicksand;
}
button {
  font-family: font_primary_button, Nunito, Quicksand;
  box-shadow: 0px 0px 12px 0px #43609433;
}
button.secondary {
  font-family: font_secondary_button, Nunito, Quicksand;
}

/*BUTTONS MAIN STYLE*/
a{
  text-decoration: none;
}
button:disabled{
  opacity: 0.7;
  cursor: not-allowed;
  background: #E2E2E2;
  color: #BABDCC;
  border: 0;
}

a[disabled]{
  pointer-events: none;
  color: #E2E2E2;
  cursor: not-allowed;
}



/*Loading Text*/
.loading_text {
  clip-path: inset(0 16pt 0 0);
  animation: l 1s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -6pt 0 0)
  }
}


/*CONTROLLERS*/
.bottom_controller{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 0px 12px 0px rgba(125, 125, 125, 0.20);
  padding: 16px 24px;
  background: #fff;
  border-radius: 16px 16px 0px 0px;
}
.bottom_controller.no_shadow {
  box-shadow: none;
}


/*ANIMATIONS*/
.fade-in-animation {
  animation: fade-in 3s;
}
.fade-in-animation-fast {
  animation: fade-in 1.5s;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 100;
  }
}

/*LISTS*/
.st_list{
  list-style: none;
  padding-left: 0;
  margin-left: 0;
}
.st_list li{
  margin-bottom: 24px;
}
.st_list li, .st_list li label{
  color: #454545;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.st_list li img{
  margin-right: 16px;
}

/*DIVIDER*/
.divider{
  height: 0.5px;
  color: #BABDCC;
}

/*CURSOR*/
.pointer{
  cursor: pointer;
}

/*TOAST*/
.Toastify__toast{
  border-radius: 100px !important;
}
@media screen and (max-width:768px) {
  .Toastify__toast-container{
    width: 75vw;
    right: calc(env(safe-area-inset-right) + 15px);
    left: unset !important;
    top: calc(env(safe-area-inset-top) + 15px)
  }
}


/*NAVIGATION CHIPS*/
.navigation_chips__container {
  display: flex;
  align-items: center;
  overflow-x: auto;
}
.navigation_chip{
  padding: 8px 24px 8px 24px;
  border-radius: 100px;
  border: 2px solid #E2E2E2;
  background: #FFFDFC;
  color: #6D6F73;
  margin-right: 8px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-family: font_link, Nunito, Quicksand;
}
.navigation_chip img{
  margin-right: 4px;
}
.navigation_chip.active {
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
  font-weight: 700;
  opacity: 1;
}

/*ACCORDION*/
.accordion {
  --bs-accordion-border-width: 0;
  --bs-accordion-active-bg: #fff;
  outline: none;
  box-shadow: none;
}
.accordion-header{
  box-shadow: 0px 0px 12px 0px #7D7D7D33;
  border-radius: 16px;
  margin-top: 16px;
}
.accordion-header .accordion-button {
  border-radius: 16px !important;
}

.react-modal-sheet-container {
  border-radius: 24px 24px 0 0 !important;
}


/*CONTENT*/
.content_section{
  padding: 16px;
}

@media screen and (max-width: 768px){
  .content_section{
    padding: 0;
  }
}

/*LOADER*/
.loader {
  width: 100%;
  height: 100%;
  background:#373737BF;
  display: inline-block;
  border-radius: 50%;
  box-sizing: border-box;
  animation: animloader 1s ease-in infinite;
}

@keyframes animloader {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.custom_tooltip{
  background: #FFFDFC !important;
  color: #454545;
  border-radius: 16px;
  --bs-tooltip-max-width: 250px;
  --bs-tooltip-bg: #FFFDFC
}

.custom_tooltip .tooltip-arrow {
  background: #FFFDFC !important;
}

.custom_tooltip .tooltip-inner{
  background: #FFFDFC !important;
  color: #454545;
  box-shadow: 0px 0px 12px 0px #7D7D7D33;
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 0;
}
